/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.landing-container {
  background-image: url('./stockimage1.jpg'); 
  background-size: cover;           
  background-position: center;       
  min-height: 100vh;
  display: flex;
  flex-direction: column; 
  align-items: center;    
  justify-content: center; 
}

.logo {
  position: fixed; /* or position: absolute; */
  top:5px; /* Adjust the top position as needed */
  left: 50%; /* Center the logo horizontally */
  transform: translateX(-50%); /* Center the logo horizontally */
  z-index: 999; /* Ensure the logo appears on top of other content */
  width: 200px;
}
.content {  /* Keep other content-related styles */
  text-align: center;
  padding: 40px;
  border-radius: 10px;
  background-color: rgba(17, 24, 39, 0.8); 
 }

.title {
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #00FFD1;
}

.subtitle {
  margin-bottom: 30px;
  color: #D0D7DE;
} 

.waitlist input[type="email"] {
  padding: 15px;
  width: 300px;
  max-width: 100%;
  border: 1px solid #D0D7DE;
  border-radius: 8px;
  margin-right: 10px;
  background-color: #1a2130;
  color: #D0D7DE;
}

.waitlist button {
  padding: 15px 30px;
  background-color: #00FFD1; 
  color: #111827;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
}

/* Make the "App Coming Soon" section more vibrant */
.subtitle {
  margin-bottom: 30px;
  color: #FFFFFF; /* Vibrant white color */
  font-size: 1.2em; /* Maintain font size */
  text-transform: uppercase; /* Uppercase text */
  letter-spacing: 2px; /* Add letter spacing for emphasis */
  animation: pulse 1.5s infinite alternate; /* Add pulsating animation */
}


.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center;
}

.dialog h2 {
  color: #00FFD1;
  margin-bottom: 10px;
}

.dialog p {
  color: #333;
  margin-bottom: 20px;
}

.dialog button {
  padding: 10px 20px;
  background-color: #00FFD1;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dialog button:hover {
  background-color: #00ccaa; /* Darker shade on hover */
}


@keyframes pulse {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}



/* Media Queries for Responsiveness */
@media (max-width: 600px) {
  .title {
    font-size: 2em;
  }

  .waitlist input[type="email"] {
    width: calc(100% - 20px); /* Adjust for padding and margin */
    margin-right: 0;
    margin-bottom: 10px;
  }

  .landing-container {
    background-size: cover; /* Adjust background size to zoom out slightly */
  }
}
